import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Contactus from "./Components/Contactus";
import Exploregames from "./Components/Exploregames";
import Gamedescription from "./Components/Gamedescription";
import Pricing from "./Components/Pricing";
import Gameworkspace from "./Components/Gameworkspace";
import Gameworkspace2 from "./Components/Gameworkspace2";
import Gameworkspace3 from "./Components/Gameworkspace3";
import Gameworkspace4 from "./Components/Gameworkspace4";
import Speechtherapyhub from "./Components/Speechtherapyhub";
import Speechtherapyhub_new from "./Components/Speechtherapyhub_new";
import Occupationaltherapyhub from "./Components/Occupationaltherapyhub";
import Behavioraltherapyhub from "./Components/Behavioraltherapisthub";
import Schoolandorganisation from "./Components/Schoolandorganisation";
import Myaccount from "./Components/Myaccount";
import Login from "./Components/Login";
import Notification from "./Components/Notification";
import Myprofile from "./Components/Myprofile";
import Forgotpassword from "./Components/Forgotpassword";

function App() {
  return (
    <>
      <Navbar />
        <Routes>
          <Route path="/"element={<Home/>} ></Route>
          <Route path="about" element={<About/>}></Route>
          <Route path="contactus" element={<Contactus/>}></Route>
          <Route path="exploregames" element={<Exploregames/>}></Route>
          <Route path="/Gamedescription/:id" element={<Gamedescription/>}></Route>
          <Route path="pricing" element={<Pricing/>}></Route>
          <Route path="gameworkspace" element={<Gameworkspace/>}></Route>
          <Route path="gameworkspace2" element={<Gameworkspace2/>}></Route>
          <Route path="gameworkspace3" element={<Gameworkspace3/>}></Route>
          <Route path="gameworkspace4" element={<Gameworkspace4/>}></Route>
          <Route path="speechtherapyhub" element={<Speechtherapyhub/>}></Route>
          <Route path="speechtherapyhub_new" element={<Speechtherapyhub_new/>}></Route>
          <Route path="occupationaltherapy" element={<Occupationaltherapyhub/>}></Route>
          <Route path="behavioraltherapy" element={<Behavioraltherapyhub/>}></Route>
          <Route path="schoolandorganisation" element={<Schoolandorganisation/>}></Route>
          <Route path="forgotpassword" element={<Forgotpassword/>}></Route>
          <Route path="myaccount" element={<Myaccount/>}></Route>
          <Route path="login" element={<Login/>}></Route>
          <Route path="notification" element={<Notification/>}></Route>
          <Route path="myprofile" element={<Myprofile/>}></Route>
        </Routes>
        <Footer/>
    </>
  );
}

export default App;
